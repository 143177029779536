/* eslint-disable max-lines */
import { forwardRef, useCallback } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import createCutPaperPath from '@utils/create-cut-paper';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { generateVariantUrl, getVariantByOption, LOCALE_CODES, PRODUCT_TYPES } from '@utils/index';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import { HeroFields } from '@ts/contentful';
import { ContentfulButton, ContentfulPicture, CutPaper, Flex, Heading, Link, Lozenge, Video } from '@components';
import styles from './Hero.module.scss';
import type { Video as VideoType } from '@ts/shopify-storefront-api';

type HeroSubtextProps = {
	subtextContent: string;
	titleColor: string;
	slideIndex: number;
	showHighlight: boolean;
	highlightOnLeft: boolean;
	cutPaperPath: string;
	highlightTextColor: string;
	highlight: string;
	highlightBackgroundColor: string;
};

const HeroSubtext = ({
	subtextContent,
	titleColor,
	slideIndex,
	showHighlight,
	highlightOnLeft,
	highlightTextColor,
	cutPaperPath,
	highlight,
	highlightBackgroundColor,
}: HeroSubtextProps) => {
	const SPLIT_CHAR = `|`;

	if (!subtextContent) {
		return null;
	}

	const shouldSplitContent = subtextContent.includes(SPLIT_CHAR);

	const subtextLines = shouldSplitContent
		? subtextContent.split(SPLIT_CHAR).map(line => {
				if (line.length === 0) {
					return <br key={'subtext-line-break'} />;
				}

				return (
					<span
						key={`subtext-line-${line.slice(0, 5)}`}
						className={styles['subtext']}
						style={{ color: titleColor ?? 'black' }}
					>
						{line}
					</span>
				);
			})
		: null;

	return (
		<Flex
			className={`heroSlide-${slideIndex}-SubText`}
			align={shouldSplitContent ? 'start' : 'center'}
			column={shouldSplitContent}
		>
			{showHighlight && highlightOnLeft ? (
				<CutPaper
					cutPaperPath={cutPaperPath}
					textColor={highlightTextColor ?? 'black'}
					backgroundColor={highlightBackgroundColor ?? 'transparent'}
					padding={'4px 8px 6px'}
					className={styles['cut-paper']}
				>
					<span className={styles['highlight']}>{highlight}</span>
				</CutPaper>
			) : null}
			{subtextLines ? (
				subtextLines
			) : (
				<span className={styles['subtext']} style={{ color: titleColor ?? 'black' }}>
					{subtextContent}
				</span>
			)}
			{showHighlight && !highlightOnLeft ? (
				<CutPaper
					cutPaperPath={cutPaperPath}
					textColor={highlightTextColor ?? 'black'}
					backgroundColor={highlightBackgroundColor ?? 'transparent'}
					padding={'4px 8px 6px'}
					className={styles['cut-paper']}
				>
					<span className={styles['highlight']}>{highlight}</span>
				</CutPaper>
			) : null}
		</Flex>
	);
};

/**
 * Contentful Hero Component
 *
 * @contentType hero
 * @resource https://app.contentful.com/spaces/a8mjl2rcjwa7/content_types/hero/fields
 */
const Hero = forwardRef<HTMLDivElement, HeroFields>(
	(
		{
			buttonGrouping,
			componentType,
			imageMobile,
			imageDesktop,
			title,
			page,
			subtext,
			slideIndex = 0,
			highlight = null,
			highlightTextColor = null,
			highlightBackgroundColor = null,
			highlightOnLeft = true,
			tag = null,
			type = 'Default',
			backgroundColor = null,
			product = null,
			frameShape = null,
			titleColor = null,
			linkButton = null,
			responsive = false,
			asBanner = false,
			size = 'medium',
			className = null,
			children,
			videoUrl,
			videoUrlDesktop,
			logo,
		},
		ref
	) => {
		let activeVariant = product?.variants[0];

		if (product && product?.type.includes(PRODUCT_TYPES.TOP_FRAME) && frameShape) {
			activeVariant = getVariantByOption(product, frameShape);
		}

		// const imageProduct = activeVariant ? activeVariant.image : product?.images[0];

		const isMobile = useIsMobile();
		const hasMounted = useHasMounted();
		const isMobileMounted = hasMounted && isMobile;
		const videoSrc = isMobileMounted ? videoUrl : (videoUrlDesktop ?? videoUrl);
		const videoPoster = isMobileMounted ? imageMobile?.url : (imageDesktop?.url ?? imageMobile?.url);
		const { locale } = useRouter();

		const variantUrl = activeVariant ? generateVariantUrl({ variant: activeVariant, option: activeVariant?.option }) : '';
		let headingTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
		let showHighlight = !!highlight;
		let showTag = !!tag;
		switch (type) {
			case 'Plp':
				showHighlight = false;
				break;
			case 'Compact':
				headingTag = 'h3';
				showHighlight = false;
				break;
			case 'Full-Bleed-Plp':
				headingTag = 'h3';
				showHighlight = false;
				break;
			default:
				headingTag = 'h1';
				showTag = false;
				break;
		}

		const cutPaperPath = createCutPaperPath('bubblebath');
		const heroClasses = cn(styles['hero'], {
			[styles['default']]: type === 'Default',
			[styles['plp']]: type === 'Plp',
			[styles['compact']]: type === 'Compact',
			[styles['full-bleed']]: type === 'Full-Bleed' || type === 'Center-Content',
			[styles['full-bleed-plp']]: type === 'Full-Bleed-Plp',
			[styles['banner-medium']]: asBanner && size === 'medium',
			[styles['banner-small']]: asBanner && size === 'small',
			[styles['banner-responsive']]: asBanner && size === 'responsive',
			[styles['video']]: type === 'Video',
			[styles['big-image']]: type === 'Big-Image',
		});

		const subtextContent = subtext || product?.metafields.shortDescription;

		const TitleTag = useCallback(() => {
			const cutPaperPath = createCutPaperPath('blueberry');
			const wrapperPath = createCutPaperPath('strawberry');
			const principalPaper = tag && (
				<CutPaper
					cutPaperPath={cutPaperPath}
					textColor={tag?.textColor ?? 'black'}
					backgroundColor={tag?.textBackgroundColor ?? 'transparent'}
					padding={'4px 8px 6px'}
					className={styles['cut-paper']}
				>
					<Heading tag={headingTag}>{tag?.text}</Heading>
				</CutPaper>
			);
			return tag?.secondaryBackgroundColor ? (
				<CutPaper
					cutPaperPath={wrapperPath}
					backgroundColor={tag?.secondaryBackgroundColor ?? 'transparent'}
					padding={'4px 0px 6px 8px'}
				>
					{principalPaper}
				</CutPaper>
			) : (
				principalPaper
			);
		}, [tag, headingTag]);

		return (
			<>
				<div
					ref={ref}
					className={cn({
						[className]: !!className,
						[styles['hero-container']]: responsive,
					})}
					style={{ height: 'inherit' }}
				>
					<div
						className={heroClasses}
						data-component-type={componentType}
						data-page-type={page}
						data-slide-index={slideIndex}
						style={backgroundColor ? { backgroundColor } : {}}
					>
						<Flex
							className={cn(
								styles['content-container'],
								{ [styles['responsive']]: responsive },
								{ [styles['center-content']]: type === 'Center-Content' }
							)}
							column
						>
							{!!logo && logo?.url && (
								<ContentfulPicture
									className={styles['logo']}
									images={{ desktop: logo, mobile: logo }}
									style={{ width: isMobile ? 'auto' : (logo?.width ?? 'auto') }}
								/>
							)}
							{showTag && tag.shape !== 'cutpaper' ? (
								<Lozenge
									shape={tag.shape}
									backgroundColor={tag.textBackgroundColor}
									color={tag.textColor}
									text={tag.text}
									extraClasses={styles['tag']}
								/>
							) : (
								<TitleTag />
							)}
							<Heading
								tag={headingTag}
								style={{ color: titleColor ?? 'black' }}
								className={`heroSlide-${slideIndex}-HeadingText ${styles['title']}`}
							>
								{title || product?.name}
							</Heading>
							{subtextContent && (
								<HeroSubtext
									subtextContent={subtextContent}
									titleColor={titleColor}
									slideIndex={slideIndex}
									showHighlight={showHighlight}
									highlightOnLeft={highlightOnLeft}
									cutPaperPath={cutPaperPath}
									highlightTextColor={highlightTextColor}
									highlight={highlight}
									highlightBackgroundColor={highlightBackgroundColor}
								/>
							)}
							<Flex className={styles['button-grouping']} align='start' gap={3} data-has-product={!!product}>
								{product && (
									<div className={`heroCTA-0 ${styles['button-cart']}`}>
										<ContentfulButton
											color='white'
											fullWidth={false}
											link=''
											size='medium'
											text='Shop Now'
											price={activeVariant?.price.amount}
											variant={activeVariant}
											keyCart={CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH}
											valueCart={variantUrl}
										/>
									</div>
								)}
								{buttonGrouping?.map((button, index) => {
									if (locale === LOCALE_CODES.GB && button.text === 'Shop Kids') {
										return;
									}
									return (
										<div
											key={`button-${index}`}
											className={cn(
												`heroSlide-${slideIndex}-CTA-${!!product ? index + 1 : index}`,
												styles['button-contenful'],
												{
													[styles['button-hidden']]:
														(type === 'Plp' || type === 'Compact') && index > 0,
												}
											)}
										>
											<ContentfulButton key={index} {...button} />
										</div>
									);
								})}
								{children && <div className={styles['children-container']}>{children}</div>}
							</Flex>
							{linkButton && (
								<Link
									key={linkButton.text}
									className={`heroLink ${styles['link']}`}
									href={linkButton.link ?? '#'}
								>
									{linkButton.text}
								</Link>
							)}
						</Flex>
						{videoUrl ? (
							<Video
								video={
									{
										sources: [
											{
												url: videoSrc,
												mimeType: 'video/mp4',
											},
										],
									} as VideoType
								}
								poster={videoPoster}
								mobile={isMobileMounted}
							/>
						) : !!imageMobile.url && !!imageDesktop.url ? (
							<ContentfulPicture
								images={{
									mobile: imageMobile,
									desktop: imageDesktop,
								}}
								fetchPriority={slideIndex === 0 ? 'high' : 'auto'}
								loading={slideIndex === 0 ? 'eager' : 'lazy'}
								className={`heroSlideImage-${slideIndex} ${styles['image-container']}`}
								ignoreWidthParam
							/>
						) : null}
					</div>
				</div>
			</>
		);
	}
);

Hero.displayName = 'Hero';

export default Hero;
